import React from "react"
import DownLoadLink from "../../../components/downloadLink/DownloadLink"
import { usePageContext } from "../../../components/pageContext/pageContext"
import Section from "../../../components/section/Section"
import exportIcon from "../../../assets/icons/export.svg"

interface PurchaseTypes {
  name: string
  description: string
  status: string
  downloadBtn: string
  downloadLink: string
}

const PurchasesSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { purchases, heading } = data.data.content.PurchasesFirstSectionConfig
  const { lang } = usePageContext() as any

  return (
    <Section className={`${className}`}>
      <h2 className="sova-section-child sova-h2">{heading}</h2>
      {/* <div className="sova-section-container-purchases"> */}
      {purchases.map(({ name, description, status, downloadBtn, downloadLink }: any, key: number) => (
        <div className="sova-section-child-container_purchase-1" key={key}>
          <div className="sova-section-child sova-purchase-heading">
            <h3 className="sova-h3">{name}</h3>
            <div className="sova-purchase-status">{status}</div>
          </div>

          <p className="sova-section-child sova-section-child_purchase-1 sova-p-tiny">{description}</p>
          <div className="sova-section-purchase-download">
            <img src={exportIcon} className="sova-icon" />
            <DownLoadLink name={downloadBtn} lang={lang} value={downloadLink} className="sova-purchase-link" />
          </div>
        </div>
      ))}
      {/* </div> */}
    </Section>
  )
}

PurchasesSectionFirst.displayName = "PurchasesSectionFirst"

export default PurchasesSectionFirst
