import React from "react"
import PurchasesSectionFirst from "./purchasesSections/purchasesSectionFirst"

const sections = [{ component: (key: number, className: string, data: any) => <PurchasesSectionFirst data={data} className={className} key={key} /> }]

const PurchasesSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-purchases sova-section-purchases_${i}`, data))}</>
}

PurchasesSections.displayName = "PurchasesSections"

export default PurchasesSections
