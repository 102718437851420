import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO/seo"
import Layout from "../layout/layout"
import PurchasesSections from "../pagesStructures/purchases/purchasesSections"

const Purchases = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiPurchases.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="404">
      <SEO title={pageContent.content.title} />
      <PurchasesSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default Purchases

export const query = graphql`
  query Purchases($url: String) {
    strapiPurchases(url: { eq: $url }) {
      content {
        content {
          title
          PurchasesFirstSectionConfig {
            heading
            purchases {
              name
              description
              status
              downloadBtn
              downloadLink
            }
          }
        }
        language
      }
    }
  }
`
